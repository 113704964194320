<template>
  <div class="pastBox">
    <div ref="detaEcharts" id="detaEcharts"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: 'detaEcharts',
    data() {
      return {

      }
    },
    props:{
      msg:{
        type:Object,
        default:0,
      }
    },
    mounted() {
      this.myCharts();
    },
    created(){
      
    },
    methods: {
      myCharts() {
        var detaEcharts = this.$echarts.init(this.$refs.detaEcharts);
        var getmax = [100];
        var getvalue = [this.msg.value];
        var axislineColor = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          // {
          //   offset: 0,
          //   color: "#2E64EF",
          // },
          {
            offset: 1,
            color: this.msg.color,
          },
        ]);
        var option = {
          grid: {
            left: "3%",
            right: "3%",
            top: "10%",
            bottom: "10%",
            containLabel: true,
          },
          series: [
            {
              center: [-1000, -1000], //仪表的位置
              type: "gauge", //统计图类型为仪表
              radius: "97%", //统计图的半径大小
              min: 0, //最小刻度
              max: 100, //最大刻度
              splitNumber: 10, //刻度数量
              startAngle: 0, //开始刻度的角度
              endAngle: 180, //结束刻度的角度
              axisLine: {
                //设置默认刻度盘上的刻度不显示，重新定义刻度盘
                show: false,
                lineStyle: {
                  width: 100,
                  color: [[1, "rgba(255,255,255,0)"]],
                },
              }, //仪表盘轴线
              axisTick: {
                show: false,
                splitNumber: 0, //刻度的段落数
                lineStyle: {
                  color: "#fff",
                  width: 0, //刻度的宽度
                },
                length: 0, //刻度的长度
              }, //刻度样式
              splitLine: {
                //文字和刻度的偏移量
                show: true,
                length: -1120, //偏移的长度
                lineStyle: {
                  color: "#fff",
                },
              },
            },
            {
              radius: "100%",
              center: ["50%", "80%"],
              min: 0,
              max: getmax,
              startAngle: 180,
              endAngle: 0,
              type: "gauge",
              axisLabel: {
                show: false,
              },
              detail: {
                show: true,
                //formatter: '1\n性格指数',
                // formatter: ["{value}", "{name|性格指数}"].join("\n"),
                formatter: ["{value}" + '%'].join("\n"),
                rich: {
                  name: {
                    fontSize: 16,
                    lineHeight: 30,
                    color: "#666666",
                  },
                },
                // 数字百分比颜色
                color: this.msg.color,
                fontSize: 20,
                fontWeight: "bolder",
                offsetCenter: [0, -10],
              },

              axisLine: {
                lineStyle: {
                  width: 10,
                  color: [
                    [getvalue / getmax, axislineColor],
                    [1, "#DCDCDC"],
                  ],
                },
              },
              pointer: {
                show: false,
              },
              itemStyle: {
                color: "#DCDCDC",
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              data: [
                {
                  value: getvalue,
                },
              ],
            },
          ],
        };
        detaEcharts.setOption(option);
      }
    }
  }
</script>

<style lang="less" scoped>
  .pastBox {
    width: 100%;
    height: 100%;

    #detaEcharts {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
</style>